export const items = [
  {
    icon: "mdi-view-dashboard-outline",
    title: "Dashboards",
    group: "dashboard",
    to: "app/dashboard",
    children: [
      {
        icon: "mdi-chart-line",
        title: "Performance",
        group: "dashboard",
        to: "performances"
      },
      {
        icon: "mdi-list-status",
        title: "Service Status",
        group: "dashboard",
        to: "service-status"
      },
      {
        icon: "mdi-database-outline",
        title: "Database",
        group: "dashboard",
        to: "database"
      },
      /*{
        icon: "mdi-select-compare",
        title: " Compare Stats",
        group: "dashboard",
        to: "compare-stats"
      },*/
    ]
  },
  {
    icon: "mdi-cog-outline",
    title: "Manage",
    group: "manage",
    to: "app/manage",
    children: [
      {
        icon: "mdi-account-outline",
        title: "Accounts",
        to: "accounts"
      },
      
      {
        icon: "mdi-account-group-outline",
        title: "Roles",
        to: "roles"
      },  
      {
        icon: "mdi-account-box-multiple-outline",
        title: "Users",
        to: "users"
      },
      {
        icon: "mdi-contacts-outline",
        title: "Advertisers",
        to: "advertisers"
      },
      {
        icon: "mdi-store-outline",
        title: "Vendors",
        to: "vendors"
      },
      /*{
        icon: "mdi-currency-usd",
        title: "Vendor Fees",
        to: "Vendor Fees"
      },*/
      {
        icon: "mdi-format-list-text",
        title: "Activity Logs",
        to: "activity-logs"
      },
      {
        icon: "mdi-segment",
        title: "Segments Mapping",
        to: "segment-mapping"
      },
      {
        icon: "mdi-apps",
        title: "Apps Lookup",
        to: "app-lookups"
      },
      {
        icon: "mdi-theater",
        title: "Agencies",
        to: "agencies"
      },
      {
        icon: "mdi-semantic-web",
        title: "Creative Segments",
        to: "creative-segments"
      },
      {
        icon: "mdi-warehouse",
        title: "Manufacturers",
        to: "manufacturers"
      },
      {
        icon: "mdi-web-check",
        title: "Web Segments",
        to: "web-segments"
      },      
      {
        icon: "mdi-currency-usd",
        title: "Platform Fees",
        to: "platform-fees"
      },
      /*{
        icon: "mdi-checkbox-multiple-blank-outline",
        title: "Third Parties",
        to: "third-parties"
      },
      {
        icon: "mdi-circle-medium",
        title: "Custom Lists",
        to: "custom-lists"
      },
      {
        icon: "mdi-circle-medium",
        title: "Deals",
        to: "deals"
      }, 
      {
        icon: "mdi-circle-medium",
        title: "Lists",
        to: "lists"
      },
      {
        icon: "mdi-circle-medium",
        title: "Targeting Expressions",
        to: "targeting-expressions"
      },*/
    ]
  },
  {
    icon: "mdi-api",
    title: "API",
    group: "api",
    to: "api",
    children: [
      {
        icon: "mdi-key",
        title: "Keys",
        to: "keys"
      },
      {
        icon: "mdi-link",
        title: "Endpoints",
        to: "endpoints"
      },
    ],
  },  
  {
    icon: "mdi-check-circle-outline",
    title: "DSP Checkers",
    group: "checker",
    to: "app/checker",
    children: [
      {
        icon: "mdi-playlist-play",
        title: "Creatives",
        to: "creatives"
      },
      {
        icon: "mdi-video-outline",
        title: "Assets",
        to: "assets"
      },  
      {
        icon: "mdi-format-list-group",
        title: "Campaigns",
        to: "campaigns"
      },
      {
        icon: "mdi-creative-commons",
        title: "Creative Content Munge",
        to: "creative-content-munge"
      },
      {
        icon: "mdi-currency-usd-off",
        title: "Budget Monitor",
        to: "budget-monitor"
      },
      {
        icon: "mdi-notification-clear-all",
        title: "Line Items",
        to: "line-items"
      },
      {
        icon: "mdi-storage-tank-outline",
        title: "Redis",
        to: "redis"
      },
      {
        icon: "mdi-traffic-light-outline",
        title: "Traffic Stats",
        to: "traffic-stats"
      },
    ]
  }, 
  {
    icon: "mdi-check-circle-outline",
    title: "FATE Checkers",
    group: "fate",
    to: "app/fate",
    children: [
      {
        icon: "mdi-playlist-play",
        title: "Creative Videos",
        to: "creative-videos"
      }, 
    ]
  },
  {
    icon: "mdi-progress-wrench",
    title: "Miscellaneous",
    group: "miscellaneous",
    to: "miscellaneous",
    children: [
      /*{
        icon: "mdi-city",
        title: "City",
        to: "cities"
      },*/
      {
        icon: "mdi-map",
        title: "Country",
        to: "countries"
      },
      /*{
        icon: "mdi-source-branch",
        title: "Inventory Sources",
        to: "inventory-sources"
      },*/
      {
        icon: "mdi-alphabet-latin",
        title: "Languages",
        to: "languages"
      },
      {
        icon: "mdi-map-marker-path",
        title: "Metros",
        to: "metros"
      },
      {
        icon: "mdi-map-marker-radius",
        title: "Regions",
        to: "regions"
      }, 
      {
        icon: "mdi-view-dashboard-outline",
        title: "Dashboards",
        to: "dashboards"
      },
      {
        icon: "mdi-circle-medium",
        title: "Reports",
        to: "reports"
      },
      {
        icon: "mdi-circle-medium",
        title: "Report Fields",
        to: "report-fields"
      },
      {
        icon: "mdi-circle-medium",
        title: "Operating Systems",
        to: "operating-systems"
      },      
     /* {
        icon: "mdi-circle-medium",
        title: "Bid Modifiers",
        to: "bid-modifiers"
      },
      {
        icon: "mdi-circle-medium",
        title: "Delivery Modifiers",
        to: "delivery-modifiers"
      },*/
      {
        icon: "mdi-circle-medium",
        title: "Platforms",
        to: "platforms"
      },
    ],
  },
  
  /*
  {
    icon: "mdi-account-supervisor-outline",
    title: "Admin",
    group: "admin",
    children: [
      // { title: "Platforms", icon: "mdi-circle-medium", to: "platforms" },
      { title: "Accounts", icon: "mdi-circle-medium", to: "accounts" },      
      // { title: "Account Settings", icon: "mdi-circle-medium", to: "account_settings" },
      // { title: "Alert Settings", icon: "mdi-circle-medium", to: "alert_settings" },
      // { title: "Presets", icon: "mdi-circle-medium", to: "presets" },
      { title: "Roles", icon: "mdi-circle-medium", to: "roles" },
      { title: "Users", icon: "mdi-circle-medium", to: "users" },
      { title: "Advertisers", icon: "mdi-circle-medium", to: "advertisers" },
      { title: "Vendors", icon: "mdi-circle-medium", to: "vendors" },
      { title: "Activity Logs", icon: "mdi-circle-medium", to: "activity_logs" },
      { title: "Creatives", icon: "mdi-circle-medium", to: "creatives" },
      { title: "Assets", icon: "mdi-circle-medium", to: "assets" },
      { title: "Campaign Checker", icon: "mdi-circle-medium", to: "campaign_checker" },
      { title: "Line Item Checker", icon: "mdi-circle-medium", to: "line_item_checker" },
      { title: "Traffic Stats Checker", icon: "mdi-circle-medium", to: "traffic_stats_checker" },
    ]
  }*/
]
